<template>
  <PVMenubar v-if="false" :model="menus">
    <template #end>
      <PVButton label="Log Out" icon="pi pi-sign-out" @click="authenticationStore.logout()"/>
    </template>
  </PVMenubar>
  <PVMegaMenu v-if="false" :model="[{label:'Home',icon:'pi pi-home'}]" orientation="vertical"/>
  <PVToast position="top-right" @close="done($event)" @life-end="done($event)"/>
  <NavigationToolBar id="navigation-toolbar"/>
  <div class="pt-10" style="max-width: 2560px">
    <RouterView/>
  </div>
</template>

<script>
import NavigationToolBar from "../components/common/navigation/NavigationToolBar.vue";
import { useAppearanceStore } from "../store/appearance";
import { useAuthStore } from "../store/authentication";
import { useVersioningStore } from "../store/versioning";
import PVMenubar from 'primevue/menubar';
import PVMegaMenu from 'primevue/megamenu';
import PVToast from 'primevue/toast';

export default {
  name: 'App',
  components: { NavigationToolBar, PVMenubar, PVMegaMenu, PVToast },
  setup() {
    const appearanceStore = useAppearanceStore();
    const authenticationStore = useAuthStore();
    const versionStore = useVersioningStore();
    return { appearanceStore, authenticationStore, versionStore };
  },
  computed:{
    menus() {
      return [
        { label:'Home',icon:'pi pi-home',
          items:[
            { label:'User',icon:'pi pi-home' },
            { label:'Patients',icon:'pi pi-home' },
            { label:'Tags',icon:'pi pi-home' },
            { label:'Monitoring',icon:'pi pi-home' },
            { label:'Monitoring(Beta)',icon:'pi pi-home' },
            { label:'Test Features',icon:'pi pi-home' },
            { label:'App Users',icon:'pi pi-home' },
            { label:'Automated Reports',icon:'pi pi-home' },
            { label:'Dark Mode',icon:'pi pi-home' },
            { label:'Browser Timezone',icon:'pi pi-home' },
            { label:'Change Permissions',icon:'pi pi-home' },
            { label:'Privacy Policy',icon:'pi pi-home' },
            { label:'Terms & Conditions',icon:'pi pi-home' },
          ],
        }
      ];
    },
    updateAvailable() { return this.versionStore.updateAvailable == true; },
  },
  watch:{
    'appearanceStore.isDark': function() {
      console.log(this.appearanceStore.isDark);
//      this.$vuetify.theme.dark = this.appearanceStore.isDark;
    },
    updateAvailable(nv) {
      if (nv == true) {
        console.log("Update availbl");
        this.$toast.add({ severity: 'info', summary: 'Info Message', detail: 'An update is available - refresh to load', life: 15000 });
      }
    },
  },
}
</script>

<style>
  #app > .p-menubar {
  margin-top:64px !important;
  background:rgb(var(--v-theme-primary)) !important;
  background:green !important;
  height:64px;
  }

  #app > .p-menubar > div.p-menubar-end {
  margin-left:auto;
  }
  
  #app > .p-menubar > ul > li > .p-menuitem-content > .p-menuitem-link > .p-menuitem-text,
  #app > .p-menubar > ul > li > .p-menuitem-content > .p-menuitem-link > .p-menuitem-icon {
    color: white !important;
  }
</style>

<style scoped>

@media print {
    #error-snackbar, #success-snackbar, #update-snackbar, #navigation-toolbar {
        display: none;
        width: 0px;
    }
}

</style>
